import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
      if (data.status == 'completed') {
          window.location.href = data.url;
      }else {
          let permission = Notification.permission;
          if (permission === "granted") {
              showNotification(data);
          } else if (Notification.permission !== "denied"){
              requestPermissionAndShow(data);
          }else{
              alert("Please allow notification");
              showInAppNotification(data);
          }
      }
  }
});


function requestPermissionAndShow(data) {
    Notification.requestPermission(function (permission) {
        if (permission === "granted") {
            showNotification(data);
        }
    });
}

function showNotification(data){
    showPushNotification(data);
    showInAppNotification(data);
}

function showPushNotification(data){
    let title;
    let body;
    if(data.call.status === "Scheduled"){
        title = "Your schedule call will be connected in 5 minutes with"
    }else{
        title = data.call.type+ " call From";
    }

    body = "Name: "+data.user.name+" and Mobile Number: "+ data.user.number;
    let pushNotification = new Notification(title, { body });
    pushNotification.onclick = () => {
        pushNotification.close();
        if(data.url){
            window.open(data.url);
        }else{
            window.parent.focus();
        }
    }
}

function showInAppNotification(data){
    if (data.id) {
        let notification = $("#notification-" + data.id);

        if(data.call.status === "Scheduled"){
            notification.find(".call-type").text(data.call.status + " call (Connect in 5 Min)")
        }else{
            notification.find(".call-type").text(data.call.type + " call")
        }
        notification.find(".name").text(data.user.name)
        notification.find(".mobile").text(data.user.number)
        if (data.url) {
            notification.attr("href", data.url).attr("target", "_blank")
        }
        notification.click(function () {
            notification.removeClass('notification-alert--shown');
        })
        notification.addClass('notification-alert--shown');
        setTimeout(function () {
            notification.removeClass('notification-alert--shown');
        }, 30000);
    }
}